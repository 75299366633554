vr-body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}

section p {
    text-align: left !important;
    align-items: center;
    padding-left: 33%;
}

.title {
    color: #333;
    text-align: center;
    margin: 20px 0;
}

section {
    margin: 20px 0;
}

h2 {
    color: #007bff;
}

p {
    line-height: 1.5;
    max-width: 500px;
}

a {
    color: #931b1b;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.vr-stuff {
    padding-left: 33%;
}

ul {
    text-align: center;
    max-width: 500px;
    text-align: left;
}

ol {
    text-align: center;
    max-width: 500px;
    text-align: left;
}

li {
    margin-bottom: 5px;
}

.image-container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.small-image {
    width: 45%;
    height: auto;
    padding: 20px;
}

.smaller-image {
    width: 25%;
    height: auto;
}

.alley {
    display: block;
    margin: 20px auto;
    max-width: 80%;
    height: auto;
}